<template>
  <v-stepper v-model="step" ref="stepperRef">
    <v-stepper-header>
      <template v-for="(section, sectionIndex) in sections">
        <v-divider v-if="sectionIndex > 0" :key="'divider-' + sectionIndex"></v-divider>
        <v-stepper-step :key="'step-' + sectionIndex" :complete="step > sectionIndex + 1" :step="sectionIndex + 1">
          <span v-html="section.title"></span>
        </v-stepper-step>
      </template>
    </v-stepper-header>
    <v-stepper-items>
      <v-stepper-content v-for="(section, sectionIndex) in sections" :key="'content-' + sectionIndex" :step="sectionIndex + 1" ref="sectionRef">
        <form-section :inputs="section.inputs" :form-data="formData" @save="$emit('save')" @update="(obj) => $emit('update', obj)" style="margin-top:10px"></form-section>
        <div class="mt-4 mb-2">
          <v-btn v-if="sectionIndex !== 0" :disabled="isUploading" class="no-print mr-2" outlined @click="back">Back</v-btn>
          <v-btn v-if="sectionIndex === sections.length - 1" :disabled="isUploading" color="primary" class="no-print" outlined @click="submit">{{ isUploading ? 'Waiting on files to upload' : submitLabel }}</v-btn>
          <v-btn v-else :disabled="isUploading" class="no-print" outlined @click="next">{{ isUploading ? 'Waiting on files to upload' : 'Next' }}</v-btn>
        </div>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>
<script>
import { ref, computed } from '@vue/composition-api'
import FormSection from './Section'
export default {
  components: {
    FormSection
  },
  props: {
    sections: {
      type: Array,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    returnButton: String,
    secondaryButton: String,
    submitLabel: {
      type: String,
      default: 'Submit'
    }
  },
  setup (props, { root, emit }) {
    const step = ref(1)
    const sectionRef = ref(null)
    const stepperRef = ref(null)

    const isUploading = computed(() => root.$store.state.forms.isUploading)

    function submit () {
      if (sectionRef.value[step.value - 1].$children[0].validate()) {
        emit('submit')
      }
    }

    function back () {
      step.value--
      // scroll the page up to the top of the section
      if (window.pageYOffset > stepperRef.value.$el.offsetTop) window.scrollTo(0, stepperRef.value.$el.offsetTop)
    }

    function next () {
      // validate the current page
      if (sectionRef.value[step.value - 1].$children[0].validate()) {
        step.value++
        // scroll the page up to the top of the section
        if (window.pageYOffset > stepperRef.value.$el.offsetTop) window.scrollTo(0, stepperRef.value.$el.offsetTop)
      }
    }

    return {
      step,
      sectionRef,
      stepperRef,
      isUploading,
      submit,
      back,
      next
    }
  }
}
</script>
