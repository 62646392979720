const checkOp = (field, op, value, formData) => {
  if (formData == null) return false
  // let parts = field.split('.')
  let leftVal = formData[field]
  // if (field in formData || parts.length === 1) {
  //   leftVal = formData[field]
  // } else if (parts.length === 2) {
  //   if (parts[0] in formData && typeof (formData[parts[0]]) === 'object' && parts[1] in formData[parts[0]]) {
  //     leftVal = formData[parts[0]][parts[1]]
  //   }
  // } else if (parts.length === 3) {
  //   if (parts[0] in formData && typeof (formData[parts[0]]) === 'object' && parts[1] in formData[parts[0]] && typeof (formData[parts[0]][parts[1]]) === 'object' && parts[2] in formData[parts[0]][parts[1]]) {
  //     leftVal = formData[parts[0]][parts[1]][parts[2]]
  //   }
  // }

  switch (op) {
    case '=':
    case 'eq':
      return leftVal === value
    case '>':
    case 'gt':
      return leftVal > value
    case '>=':
    case 'gte':
      return leftVal >= value
    case '<':
    case 'lt':
      return leftVal < value
    case '<=':
    case 'lte':
      return leftVal <= value
    case 'in':
      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          if (Array.isArray(leftVal)) {
            for (let j = 0; j < leftVal.length; j++) {
              if (leftVal[j] === value[i]) {
                return true
              }
            }
          } else if (leftVal === value[i]) {
            return true
          }
        }
        return false
      }
      break
  }
}

const checkIfs = (ifs, data) => {
  for (const { field, op, value } of ifs) {
    if (!checkOp(field, op, value, data)) {
      return false
    }
  }
  return true
}

exports.checkOp = checkOp
exports.checkIfs = checkIfs
