<template>
  <v-form lazy-validation ref="formRef">
    <v-row wrap>
      <template v-for="(input, inputIndex) in inputArr">
        <repeatable
          v-if="input.input === 'repeatable-block' && input.visible"
          :key="'block-' + inputIndex"
          :input="input"
          :form-data="formData"
          ref="repeatableBlockRefs"
          @update="(obj) => updateData(input, obj)"
        ></repeatable>
        <!-- <repeatable-block
          v-if="input.input==='repeatable-block' && input.visible"
          :key="'block-' + inputIndex"
          :settings="input.settings || { buttonAdd: true, buttonText: 'Add New', removable: true, min: 0}"
          :inputs="input.inputs"
          :label="input.label"
          :name="input.name"
          :info="input.info"
          :form-data="formData"
          ref="repeatableBlockRefs"
          @update="updateData"
        ></repeatable-block> -->
        <form-input
          v-else-if="input.visible"
          :key="'input-' + inputIndex"
          :input="input"
          :value="input.value"
          :form-data="formData"
          ref="inputRefs"
          @update="(obj) => updateData(input, obj)"
          @save="$emit('save')"
        ></form-input>
      </template>
    </v-row>
  </v-form>
</template>
<script>
import { ref, reactive, watch } from '@vue/composition-api'
import { checkOp, checkIfs } from '../inputs/checkOp'
export default {
  components: {
    FormInput: () => import('../FormInput'),
    // RepeatableBlock: () => import('../inputs/RepeatableBlock'),
    Repeatable: () => import('../inputs/Repeatable')
  },
  props: {
    inputs: {
      type: Array,
      required: true
    },
    formData: {
      type: Object,
      required: true
    }
  },
  setup (props, { emit }) {
    const inputArr = ref([])
    const inputHash = reactive({})

    watch(() => props.inputs, () => {
      // console.log('props.input changed')
      if (props.inputs.length > 0) {
        inputArr.value = []
        // console.log('props.input length > 0')
        props.inputs.forEach((item) => {
          let { label, name, input, ifs } = item
          let temp = { ...item, visible: true }
          if (input === 'html-block') {
            if (ifs == null || !Array.isArray(ifs) || ifs.length === 0) {
              inputArr.value.push({ ...item, visible: true })
              return
            }
            let i = 0
            label = 'html-block-' + i
            while (label in inputHash) {
              i++
              label = 'html-block=' + i
              if (i >= 50) return
            }
            temp.label = label
          }

          if (label in props.formData) {
            temp.value = props.formData[label]
          } else if (name != null) {
            let nameSplit = name.split('.')
            switch (nameSplit.length) {
              case 1:
                temp.value = props.formData[nameSplit[0]]
                break
              case 2:
                if (!(nameSplit[0] in props.formData)) props.formData[nameSplit[0]] = {}
                temp.value = props.formData[nameSplit[0]][nameSplit[1]]
                break
              case 3:
                if (!(nameSplit[0] in props.formData)) props.formData[nameSplit[0]] = {}
                if (!(nameSplit[1] in props.formData[nameSplit[0]])) props.formData[nameSplit[0]][nameSplit[1]] = {}
                temp.value = props.formData[nameSplit[0]][nameSplit[1]][nameSplit[2]]
                break
              case 4:
                if (!(nameSplit[0] in props.formData)) props.formData[nameSplit[0]] = {}
                if (!(nameSplit[1] in props.formData[nameSplit[0]])) props.formData[nameSplit[0]][nameSplit[1]] = {}
                if (!(nameSplit[2] in props.formData[nameSplit[0]][nameSplit[1]])) props.formData[nameSplit[0]][nameSplit[1]][nameSplit[2]] = {}
                temp.value = props.formData[nameSplit[0]][nameSplit[1]][nameSplit[2]][nameSplit[3]]
                break
            }
          } else {
            if (input === 'checkbox') temp.value = false
            else temp.value = ''
          }
          if (ifs != null && Array.isArray(ifs) && ifs.length > 0) {
            temp.visible = true
            ifs.forEach(({ field, op, value }) => {
              if (!(field in inputHash) || !(inputHash[field] in inputArr.value)) {
                // console.log('field not in hash')
                return
              }
              // Add an entry on the source field so we know to check the visibility of this field when that one changes
              if (!('dependents' in inputArr.value[inputHash[field]])) inputArr.value[inputHash[field]].dependents = []
              inputArr.value[inputHash[field]].dependents.push(label)
              // Do the check right now and see if it passes; if not, then set the visibility to false
              if (!checkOp(field, op, value)) {
                temp.visible = false
              }
            })
          }
          inputHash[label] = inputArr.value.length
          if ('name' in item) inputHash[item.name] = inputArr.value.length
          // console.log('adding to inputArr')
          inputArr.value.push(temp)
        })
      }
    })

    function updateData (input, { field, value, index, action }) {
      if (input.input === 'html-block') return
      emit('update', { field, value, index, action })
      // console.log('checking hash')
      if (field in inputHash) {
        // console.log('-checking for dependents')
        let index = inputHash[field]
        if ('dependents' in inputArr.value[index] && Array.isArray(inputArr.value[index].dependents) && inputArr.value[index].dependents.length > 0) {
          // console.log('--for each dependent...')
          inputArr.value[index].dependents.forEach((depField) => {
            // console.log('---checking ' + field + ' for ifs')
            let index = inputHash[depField]
            const { ifs } = inputArr.value[index]
            if (ifs && Array.isArray(ifs) && ifs.length > 0) {
              inputArr.value.splice(index, 1, { ...inputArr.value[index], visible: checkIfs(ifs, props.formData) })
            }
          })
        }
      }
    }

    const formRef = ref(null)
    const repeatableBlockRefs = ref(null)
    const inputRefs = ref(null)
    function validate () {
      let isValid = true
      if (repeatableBlockRefs.value != null) {
        repeatableBlockRefs.value.forEach(async (row) => {
          if (!row.validate()) isValid = false
        })
      }
      if (!formRef.value.validate()) isValid = false
      if (Array.isArray(inputRefs.value)) {
        // Validate any required signature pads
        for (let i = 0; i < inputRefs.value.length; i++) {
          if (inputRefs.value[i].$children[0].$el.classList.contains('signature-pad')) {
            if (!inputRefs.value[i].$children[0].validate()) isValid = false
          }
        }
      }
      return isValid
    }

    return {
      inputArr,
      inputHash,
      updateData,
      formRef,
      repeatableBlockRefs,
      inputRefs,
      validate
    }
  }
}
</script>
